.intro{
    width: auto;
    height: 100vh;
    right: 0px;
    top: 0px;
    /* background-color: #1A1717; */
}
.intro-content{
    display:grid;
    grid-template-columns: 1fr 1fr;
}
.intro-right{
    display: flex;
    justify-content: center;
    align-items: center;
}
.intro-right>img{
    width: 100%;
    height: 100vh;
    object-fit: cover;
    margin : 0 auto;
    transform: scaleX(-1);
    /* mask-image: linear-gradient(to bottom, black,transparent); */
}
.intro-left{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    color: white;
}
span.name{
    font-size: 3rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: #b41313;
}
h2.profession{
    font-size: 2rem;
    font-weight: 400;
    margin-bottom: 1rem;
    color:rgb(25, 118, 181);
}
.intro-button{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}
.intro-button-item{
    display: flex;
    justify-content: space-between;
    margin: 10px;
    padding: auto;
}
.intro-button >button{    
    margin : 1rem;
    padding: 0.5rem 1rem;
}
.intro-button >button:hover{
    background-color: #b41313;
    color: white;
}
.intro-contribution{
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
}
.waving-hand{
    animation-name: hello-wave;
    animation-duration: 4s;
    animation-iteration-count: infinite;
}

@keyframes hello-wave{
    0%{
        transform: rotate(-50deg);
    }
    33%{
        transform: rotate(-25deg);
    }
    67%{
        transform: rotate(0deg);
    }
    100%{
        transform: rotate(-50deg);
    }
}

@media screen and (max-width: 768px){
    .intro{
        height: 100vh;
    }
    .intro-content{
        display: grid;
        grid-template-columns: 1fr;
    }
    .intro-right>img{
        height: 50vh;
        object-fit: contain; 
        /* make image attache to bottom */
        position:relative;
        mask-image: -webkit-gradient(linear, left top, left bottom, from(rgba(0,0,0,1)), to(rgba(0,0,0,0.3)));
        bottom: 0;
    }
    .intro-left>h2{
        text-align: center;
    }
    .intro-left{
        margin-top:10%;
    }
}