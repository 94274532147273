.contact{
    width: 100%;
    height: auto;
    position: relative;
    /* linear gradient from top to bottom*/
    /* background-color: #1A1717; */
}
.contact-content{
    /* color: antiquewhite; */
    text-align: center;
}

.contact-form{
    width: 90%;
    height: auto;
    /* background: linear-gradient(to bottom, #1c1223, #1b0d0d); */
    border-radius: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin: 0 auto;
    padding: 20px;
        
}
.social-networks{
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
    margin-top: 10px;
}
.social-networks a{
    margin: 0 20px;
    text-decoration: none;
}

.contact-left{
    margin-top: 10px;
    padding: 20px;   
}
button[type="submit"]{
    width: 30%;
    height: 40px;
    border: none;
    border-radius: 10px;
    margin: 10px 0;
    padding: 0 20px;
    font-size: 1.2rem;
    outline: none;
    background-color: #b41313;
    color: white;
    cursor: pointer;
    transition: 0.5s;
}

.form-group>input{
    height: 40px;
    width: 85%;
    border: none;
    border-radius: 10px;
    margin: 10px 0;
    padding: 0 20px;
    font-size: 1.2rem;
    outline: none;
    background-color: beige;
}
.form-group>textarea{
    height: 100px;
    width: 100%;
    border: none;
    border-radius: 10px;
    margin: 10px 0;
    padding: 0 20px;
    font-size: 1.2rem;
    outline: none;
    height: fit-content;
    /* background-color: aquamarine; */
    }

.contact-right img{
    width: 80%;
    height: 50vh;
    object-fit: cover;   
}





@media screen and (max-width: 768px){
    .contact-form{
        display: flex;
        flex-direction : column-reverse;

        
    }
    .contact-right img{
        width: 100%;
        height: 50vh;
        object-fit: contain;  
    }
}