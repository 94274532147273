.header{
    position : fixed;
    top : 0;
    color: antiquewhite;
    width: 100%;
    text-align: center;
}
.header-content{
    display:grid;
    grid-template-columns: 0.5fr 0.9fr
}
.header-left>h3{
    text-align: left !important;
    margin-left: 1rem;
    font-family: monospace;
}
.header-right-content{
    margin : 0 auto;
}
ul{
    display:flex;
    flex-direction: row;
    justify-content: space-around;
    align-items: center;
    list-style: none;
    margin: 10px 0;
    padding: 0;
}
li>a{
    text-decoration: none;
    color: aquamarine;
    font-size: 1.2rem;
    font-weight: 500;
    font-family: monospace;
    line-height: normal;
}